$(document).foundation();

$(document).ready(function() {
  $('.slide').show();
  AOS.init();
});

$(window).load(function() {

  // Owl Carousels

  $(".main-slider").owlCarousel({
    navigation : false,
    loop: $('.main-slider').children().length > 1,
    items: 1,
    autoplay: true
  });

  $(".testimonial-slider ul").owlCarousel({ 
    nav: false,
    loop : true,
    items: 1,
    autoplay: true
  });



// Scroll button
$(".slide-scroll-btn").click(function() {
    $('html, body').animate({
        scrollTop: $(".top-home-text").offset().top
    }, 1000);
});

// Back to top button

  $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
          $('.scrollup').fadeIn();
      } else {
          $('.scrollup').fadeOut();
      }
  });

  $('.scrollup').click(function () {
      $("html, body").animate({
          scrollTop: 0
      }, 600);
      return false;
  });
});

$(window).load(function() {
AOS.init();
});
